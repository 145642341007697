export const SN_NET_SUITE = "netSuite"
export const NET_SUITE_INVENTORY_VARIANCES_UPDATED = "/INVENTORY_VARIANCES_UPDATED"
export const NET_SUITE_PRODUCT_STORE_SHIPMENT_METHODS_UPDATED = "/PRODUCT_STORE_SHIPMENT_METHODS_UPDATED"
export const NET_SUITE_PAYMENT_METHODS_UPDATED = "/PAYMENT_METHODS_UPDATED"
export const NET_SUITE_SALES_CHANNEL_UPDATED = "/SALES_CHANNEL_UPDATED"
export const NET_SUITE_INTEGRATION_TYPE_MAPPINGS_UPDATED = "/INTEGRATION_TYPE_MAPPINGS_UPDATED"
export const NET_SUITE_SHOPIFY_TYPE_MAPPINGS_UPDATED = "/SHOPIFY_TYPE_MAPPINGS_UPDATED"
export const NET_SUITE_SHOPIFY_SHOPS_CARRIER_SHIPMENTS_UPDATED = "/SHOPIFY_SHOPS_CARRIER_SHIPMENTS_UPDATED"
export const NET_SUITE_SHOPIFY_SHOPS_LOCATIONS_UPDATED = "/SHOPIFY_SHOPS_LOCATIONS_UPDATED"
export const NET_SUITE_FACILITIES_IDENTIFICATIONS_UPDATED = "/FACILITIES_IDENTIFICATIONS_UPDATED"
export const NET_SUITE_ENUM_GROUPS_UPDATED = "/ENUM_GROUPS_UPDATED"
export const NET_SUITE_CLEARED = "/CLEARED"